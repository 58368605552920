




import VueCkeditor from 'vue-ckeditor2'
import {Component, Emit, Prop, Vue} from 'vue-property-decorator'


@Component({
    components: {
        VueCkeditor
    }
})
export default class Ckeditor extends Vue {
    @Prop({
        default: () => {
            return ''
        }
    })
    value!: string
    @Prop({
        default: () => {
            return {}
        }
    })
    config!: string

    cfg = {
        language: 'en',
        toolbar: [
            ['Format', 'Font', 'FontSize', 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', 'RemoveFormat'],
            ['JustifyLeft',
                'JustifyCenter',
                'JustifyRight',
                'JustifyBlock'],
            ['RemoveFormat'],
            ['Source'],
            '/',
            ['TextColor', 'BGColor'],
            ['Image'],
            ['NumberedList', 'BulletedList', 'Outdent', 'Indent', 'Blockquote', 'Table'],
            ['Anchor', 'Link', 'btbutton'],
            ['ResetImg', 'LinkColor', 'LinkTarget', 'TableScroll']
        ],
        height: 600,
        format_tags: 'p;h2;h3',
        uploadUrl: this.uploadUrl,
        colorButton_colors: 'FFFFCC,4DBBB6,FFF,000'
    }
    accessToken = ''

    get uploadUrl() {
        return `${process.env.VUE_APP_DOMAIN_API}/blog/image`
    }

    get localValue(): string {
        return this.value
    }

    set localValue(value: string) {
        this.input(value)
    }

    created() {
        Object.assign(this.cfg, this.config)
        this.runGetAccessToken()
    }

    runGetAccessToken() {
        this.$auth.getAccessToken().then((token) => {
            this.accessToken = token
        })

        setTimeout(() => {
            this.runGetAccessToken()
        }, 1000 * 60 * 30)
    }

    async uploadImg(evt) {
        const xhr = evt.data.fileLoader.xhr
        xhr.setRequestHeader('Authorization', 'Bearer ' + this.accessToken)
    }

    @Emit()
    input(value: string) {
        //
    }
}

